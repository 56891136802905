<template>
  <page-content title="用户详情">
    <a-descriptions title="" bordered size="small" :column="{sm:2}">
      <a-descriptions-item label="昵称">{{user.nickName}}</a-descriptions-item>
      <a-descriptions-item label="用户ID">{{user.identityId}}</a-descriptions-item>
      <a-descriptions-item label="手机号">{{user.phone ? user.phone : "暂未绑定手机号"}}</a-descriptions-item>
      <!--        <a-descriptions-item label="邮箱">{{user.email ? user.email : "暂未绑定邮箱"}}</a-descriptions-item>-->
      <a-descriptions-item label="上次登录时间">{{this.dateFormat(user.lastLoginTime)}}</a-descriptions-item>
    </a-descriptions>

    <h3 style="margin-top: 30px">用户设备</h3>
    <!-- 表格区域 -->
    <a-table
            :columns="deviceInfoColumns"
            :dataSource="deviceInfo.dataSource"
            :pagination="false"
            size="middle"
            :loading="deviceInfo.loading"
    ></a-table>

  </page-content>

</template>
<script>
export default {
  name: 'UserDetail',
  data () {
    return {
      user: {
        nickName:"",
        identityId:"",
        phone:"",
        email:"",
        lastLoginTime: ''
      },
      deviceInfo: {
        loading: false,
        dataSource: []
      }
    }
  },
  created () {
    this.$get(`appUser/${this.$route.params.identityId}`, {}).then(r => {
      this.user = r.data
    })
    this.$get(`web/iot/device/getByUser`, {identityId:this.$route.params.identityId}).then(r => {
      this.deviceInfo.dataSource = r.data
    })
  },
  computed: {
    deviceInfoColumns () {
      return [
        {
          title: '设备名称',
          dataIndex: 'nickname'
        },
        {
          title: '设备ID',
          dataIndex: 'iotId'
        },
        {
          title: '设备MAC',
          dataIndex: 'name'
        },
        {
          title: '在线状态',
          dataIndex: 'status',
          customRender: (text, row, index) => {
            switch (text+ '' ) {
              case '1':
                return <a-tag color="green">在线</a-tag>
              default:
                return <a-tag color="cyan">离线</a-tag>
            }
          }
        },
        // {
        //   title: '绑定时间',
        //   dataIndex: 'createdAt',
        // }
      ]
    }
  },
  methods: {
    dateFormat (time) {
      var now = new Date(time)
      var year = now.getFullYear()
      var month = this.fullFormat(now.getMonth() + 1)
      var day = this.fullFormat(now.getDate())
      var hour = this.fullFormat(now.getHours())
      var minute = this.fullFormat(now.getMinutes())
      var seconds = this.fullFormat(now.getSeconds())
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds
    },
    fullFormat (time) {
      var string = time.toString()
      if (string.length === 1) {
        string = '0' + string
      }
      return string
    }
  }
}
</script>
<style lang="less" scoped>
.user-info {
  width: 560px;
  &-content {
    display: flex;
    p {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    &-left {
      margin-left: 16px;
    }
    &-right {
      margin-left: 26px;
    }
  }
}
</style>
<style lang="less">
// 修改ant样式
.userDetail {
  .device-info {
    margin: 24px 0;
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-body {
      padding-top: 0;
    }
  }
  .device-info-table {
    .ant-table-thead > tr > th {
      color: #999;
      padding: 6px 16px;
    }

  }
}
</style>
